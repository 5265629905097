<template>
    <div class="cancel-container">
        <h1 class="has-text-primary has-text-weight-bold" :class="displayOnTouch ? 'is-size-huge-mobile' : 'is-size-huge'">Annulation et modification</h1>
        <h2>Vous trouverez ci-dessous nos conditions d'annulation et de modication</h2>
        <h3 class="is-size-4 has-text-primary has-text-weight-bold">I) Les conditions de location</h3>
        <p>Le bateau skipper, propriétaire du bateau, en accord avec le bateau stockeur, celui qui met à disposition un emplacement contre rémunération, dispose deux possibilités de location.</p>
        <ul>
          <li><strong>La courte durée, moins de 30 jours :</strong> dans ce cas, l’adhésion peut se faire pour une journée, un week-end, une semaine, dix jours, etc., jusqu’à un maximum de 29 jours pleins. Le tarif consenti entre les deux parties <strong style="text-decoration:underline">est réglé dans son intégralité à la signature du contrat.</strong></li>
          <li><strong>La longue durée, au-delà de 30 jours :</strong> l’adhésion est conclue pour un mois minimum puis le nombre de mois souhaités jusqu’à une année. <strong style="text-decoration:underline">La première mensualité est payée à la signature du contrat ; les règlements suivants sont effectués de façon mensuelle à la date anniversaire du contrat.</strong></li>
        </ul>

        <h3 class="is-size-4 has-text-primary has-text-weight-bold">II) Les conditions de résiliation</h3>
        <p>Le bateau skipper, propriétaire du bateau, en accord avec le bateau stockeur, celui qui met à disposition un emplacement contre rémunération, dispose deux possibilités de location.</p>
        <ul>

          <li><strong>Dans le cas d’un stockage courte durée de moins de trente jours :</strong> Si l’annulation intervient <span style="text-decoration:underline">avant le début de la location,</span>&nbsp;<strong>le remboursement est consenti</strong> (hors frais de commission automatiquement retenus) pour le bateau skipper.<br>
          Si la résiliation est demandée <span style="text-decoration:underline">alors que la location a débuté,</span> celle-ci est rendue caduque mais <strong>le règlement intégral effectué lors de la signature ne pourra connaitre aucun remboursement.</strong>
          </li>

          <li><strong>Dans le cas d’un stockage longue durée (plus de trente jours), dont la période a été préalablement établie entre les parties :</strong> Si la demande d’annulation du contrat est souhaitée <span style="text-decoration:underline">avant que ne commence la location,</span> comme pour la location courte durée, <strong>le remboursement est consenti</strong> (hors frais de commission automatiquement retenus) pour le bateau skipper.<br>
          <strong>Si la résiliation d’un contrat de location longue durée est demandée durant l’exécution du contrat, le bateau skipper doit alors effectuer une demande écrite au minimum 7 jours avant la date anniversaire du contrat en cours. Le non-respect du préavis entraîne la prolongation de la location d’un mois.Chaque mois de location entamée est dû. Les frais de commission sont également retenus.
          </strong></li>
        </ul>
        <p>Les tarifs fixés entre bateau skipper et bateau stocker seront libres, <strong>JeStockeMonBateau.fr</strong> s’accordant le droit de fournir une grille tarifaire basée sur différents critères.</p>
        


    </div>
</template>
<script>
export default {
  name: 'Cancel',
  data () {
    return {
    }
  },
  metaInfo () {
    let siteName = this.$store.state.globalConstant.SITE_NAME,
      baseUrl = this.$store.state.globalConstant.BASE_URL,
      canonical = this.$router.resolve({ name: 'Cancel' });

    let title = (siteName+' - Modification et Annulation - Stockage de bateau chez les particuliers et professionnels - Hivernage Bateau - Stockage bateau hors d\'eau'),
      description = siteName+' est le spécialiste du stockage de bateaux chez les particuliers et professionnels avec une assurance ! La première plateforme dans le monde mettant en relation des propriétaires d\'un jardin, d\'un abri, d\'un box ou d\'un garage, avec des plaisanciers qui recherchent un endroit où stocker leur bateau. Découvrez comment modifier et annuler vos annonces sur notre plateforme.';

    return {
      // if no subcomponents specify a metaInfo.title, this title will be used
      titleTemplate: title,
      link: [
        { rel: 'canonical', href: (baseUrl+canonical.href) },
      ],
      meta: [
        { 
          vmid: 'description', 
          name: 'description', 
          content: description 
        },
        {
          'property': 'og:title',
          'content': title,
          'vmid': 'og:title'
        },
        {
          'property': 'og:description',
          'content': description,
          'vmid': 'og:description'
        },
        {
          'property': 'og:url',
          'content': (baseUrl+this.$route.fullPath),
          'vmid': 'og:url'
        },
        {
          'property': 'og:image',
          'content': (baseUrl+require('./../../assets/og-image.png')),
          'vmid': 'og:image'
        },
        {
          'property': 'og:image:secure_url',
          'content': (baseUrl+require('./../../assets/og-image.png')),
          'vmid': 'og:image:secure_url'
        },
        {
          'property': 'twitter:title',
          'content': title,
          'vmid': 'twitter:title'
        },
        {
          'property': 'twitter:description',
          'content': description,
          'vmid': 'twitter:description'
        },
        {
          'property': 'og:image:alt',
          'content': title,
          'vmid': 'og:image:alt'
        },
        {
          'property': 'twitter:image',
          'content': (baseUrl+require('./../../assets/tw-card.jpg')),
          'vmid': 'twitter:image'
        },
      ]
    }
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="css">
  .cancel-container {
    margin: 5% 30%;
  }
  ul {
    list-style-type: disc;
    list-style-position: outside;
    margin-left: 1rem;
  }
  li {
    margin-bottom: 0.5rem;
  }
  li ul {
    list-style-type: square;
    margin-top: 1rem;
    font-size: 0.8rem;
  }
  h3,
  h4 {
    margin-bottom: 1rem;
  }
  @media screen and (max-width: 2048px){
    .cancel-container {
      margin: 5% 25%;
    }
  }
  @media screen and (max-width: 1640px){
    .cancel-container {
      margin: 5% 20%;
    }
  }
  @media screen and (max-width: 1365px){
    .cancel-container {
      margin: 5% 15%;
    }
  }
  @media screen and (max-width: 1171px){
    .cancel-container {
      margin: 5% 10%;
    }
  }
  .cancel-container h1{
    padding-bottom: 5%;
  }
  .is-size-huge {
    font-size: 8rem;
    line-height: 8rem;
    hyphens: auto;
  }
  .is-size-huge-mobile{
    font-size: 6rem;
    line-height: 6rem;
  }
  h2 {
    line-height: 2rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .break {
    word-break: break-all
  }
  p {
    margin-bottom: 1rem;
    text-align: justify;
  }
  @media screen and (max-width: 1087px) {
    .container {
      margin: 5%;
    }
  }
  @media screen and (max-width: 537px) {
    .container {
      margin: 5%;
    }
    .is-size-huge-mobile{
      font-size: 3rem;
      line-height: 3rem;
    }
  }
</style>
